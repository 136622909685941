<template>
  <p>
    {{ new Date().getFullYear() }} © {{ nameApp }}
    {{ countryApp === countryColombia ? 'SAS' : '' }}
  </p>
</template>

<script>
import { nameApp, countryApp, countryColombia } from '@/constants/config'
export default {
  data() {
    return {
      nameApp,
      countryApp,
      countryColombia
    }
  }
}
</script>

<style></style>
