<script>
import { authFackMethods, notificationMethods } from '@/state/helpers'
import Layout from '../../layouts/auth'
import appConfig from '@/app.config'
import { logoApp, nameApp } from '@/constants/config'
import { mapActions, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import Footer from './components/footer.vue'

/**
 * Register component
 */
export default {
  mixins: [validationMixin],
  page: {
    title: 'Regístrate',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    Footer
  },
  data() {
    return {
      logoApp,
      nameApp,
      user: {
        firstname: '',
        lastname: '',
        email: '',
        password: ''
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false
    }
  },
  validations: {
    user: {
      firstname: {
        required,
        minLength: minLength(1)
      },
      lastname: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4)
      },
      email: {
        required,
        email,
        minLength: minLength(4)
      }
    }
  },
  computed: {
    ...mapState('authfack', ['status']),
    notification() {
      return this.$store ? this.$store.state.notification : null
    }
  },
  methods: {
    ...authFackMethods,
    ...notificationMethods,
    ...mapActions({
      register: 'user/register'
    }),
    // Try to register the user in with the email, username
    // and password they provided.
    async submitRegister() {
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()
      this.$v.user.$touch()
      if (!this.$v.user.$anyError) {
        const success = await this.register({
          email: this.user.email,
          password: this.user.password,
          last_name: this.user.lastname,
          first_name: this.user.firstname
        })
        if (success) {
          this.$router.push('/login')
        }
      }
    }
  }
}
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft p-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-7">
                <div class="text-white">
                  <h2 class="text-white">{{ nameApp }}</h2>
                  <h6 class="text-white">Regístrate en {{ nameApp }}.</h6>
                </div>
              </div>
              <div class="col-5 align-self-end mb-3">
                <img :src="logoApp" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible
              >Registro exitoso.</b-alert
            >

            <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{
              regError
            }}</b-alert>

            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="submitRegister">
              <b-form-group class="mb-3" id="name-group" label="Nombre" label-for="fistname">
                <b-form-input
                  id="firstname"
                  v-model="user.firstname"
                  type="text"
                  placeholder="Ingresa tu Nombre"
                  :class="{
                    'is-invalid': submitted && $v.user.firstname.$error
                  }"
                ></b-form-input>
                <div v-if="submitted && !$v.user.firstname.required" class="invalid-feedback">
                  El Nombre es requerido.
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="lastname-group"
                label="Apellido"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname"
                  v-model="user.lastname"
                  type="text"
                  placeholder="Ingresa tu Apellido"
                  :class="{
                    'is-invalid': submitted && $v.user.lastname.$error
                  }"
                ></b-form-input>
                <div v-if="submitted && !$v.user.lastname.required" class="invalid-feedback">
                  El Apellido es requerido.
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="email-group"
                label="Correo electrónico"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  type="email"
                  placeholder="Ingresa tu Correo Electrónico"
                  :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                ></b-form-input>
                <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                  <span v-if="!$v.user.email.required"
                    >El Correo electrónico es requerido.</span
                  >
                  <span v-if="!$v.user.email.email"
                    >Ingresa un correo electrónico valido.</span
                  >
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="password-group"
                label="Contrañeña"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="user.password"
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  :class="{
                    'is-invalid': submitted && $v.user.password.$error
                  }"
                ></b-form-input>
                <div v-if="submitted && !$v.user.password.required" class="invalid-feedback">
                  La contraseña es requerida.
                </div>
              </b-form-group>

              <div class="mt-4 d-grid">
                <b-button type="submit" variant="outline-dark" class="btn-block"
                  >Registrarme</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            ¿ Ya posees una cuenta ?
            <router-link tag="a" to="/login" class="fw-medium text-primary"
              >Iniciar sesión</router-link
            >
          </p>
          <Footer></Footer>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
